import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Pricing from "@/views/PricingTextPage.vue";
import FAQ from "@/views/FAQ.vue";
import AboutUs from "@/views/AboutUs.vue";
import Contact from "@/views/Contact.vue";
import Services from "@/views/Services.vue";
import Gallery from "@/views/Gallery.vue";
import Rules from "@/views/Rules.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Competition from "@/views/Competition.vue";

const routes = [
    { path: "/", name: "Home", component: Home, },
    { path: "/pricing", name: "Pricing", component: Pricing, },
    { path: "/faq", name: "FAQ", component: FAQ, },
    { path: "/about-us", name: "AboutUs", component: AboutUs, },
    { path: "/contact", name: "Contact", component: Contact, },
    { path: "/services", name: "Services", component: Services, },
    { path: "/gallery:gallery_category_id?", name: "Gallery", component: Gallery, },
    { path: "/rules", name: "Rules", component: Rules, },
    { path: "/privacy-policy", name: "PrivacyPolicy", component: PrivacyPolicy, },
    { path: "/competition", name: "Competition", component: Competition, },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(() => {
    window.scrollTo(0, 0);
})

export default router;