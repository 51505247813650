<template>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11334.80989022573!2d21.52251180757338!3d53.32803451579508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471e10ec7fca7453%3A0x211305104496d1c8!2sPiekarnia%20Cukiernia%20%22TWOJA%22!5e0!3m2!1spl!2spl!4v1662104969521!5m2!1spl!2spl" width="100%" height="350em" style="border:0; margin-top: 2%;" allowfullscreen="" loading="lazy"></iframe>
</template>

<script>
    export default {
        name: "MapGoogle"
    }
</script>

<style scoped>

</style>