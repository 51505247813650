<template>
    <div id="section-faq" class="section">
        <div class="conteiner" v-if="questions.length === 0">
            <code-loader primaryOpacity="0.15"></code-loader>
        </div>
        <div class="conteiner" v-else>
            <div class="section-header-end">
                <div><h4>FAQ</h4></div>
                <div class="clear"></div>
            </div>
            <div class="section-content">
                <div class="content-faq" v-for="question in questions" :key="question.frequentlyAskedQuestion.id">
                    <ul>
                        <li class="select"><strong>{{ question.translation.question }}</strong></li>
                        <li v-html="question.translation.answer"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { CodeLoader } from 'vue-content-loader';
    export default {
        name: "FAQ",
        components: {
            CodeLoader,
        },
        data() {
            return {
                questions: [
                    {
                        frequentlyAskedQuestion: {
                            id: null,
                        },
                        translation: {
                            id: null,
                            question: null,
                            answer: null,
                        }
                    }
                ]
            }
        },
        created(){
            this.getFAQByLanguageId(this.$userLanguageId);
        },
        methods: {
            async getFAQByLanguageId(languageId) {
                var self = this;
                axios.get(this.$siteUrlApi+'/faq-'+languageId)
                    .then(function (response) {
                        self.questions = response.data.frequentlyAskedQuestions;
                    })
            },
        }
    }
</script>

<style scoped>

</style>