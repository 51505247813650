<template>
    <div id="section-gallery-full" class="section">
        <div class="conteiner" v-if="pageLoaded === false">
            <code-loader primaryOpacity="0.15"></code-loader>
        </div>
        <div class="conteiner" v-else>
            <div class="section-header-end">
                <div><h4>{{ pageName }}</h4></div>
                <div class="content-preview" style="width: 100%">
                    <div class="path-menu">
                        <ul>
                            <li><a @click="getGalleryByLanguageId()" :class="categoryId === null ? 'select' : ''">{{ mainCategoryName }}</a></li>
                            <li v-for="category in categories" :key="category.category.id"><a @click="getGalleryCategoryByLanguageId(category.category.id)" :class="categoryId === category.category.id ? 'select' : ''" style="color: #835d23">{{ category.translation.name }}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="section-content" v-if="categoryId > 0">
                <div class="content-preview" v-for="(image, index) in gallery" :key="image.galleryImage.id">
                  <img v-bind:src="$siteUrlPanel+'/uploads/gallery_images/thumbnail/'+image.galleryImage.id+'.jpg'" :alt="image.translation.text_alternative" @click="galleryShowImage(index)" />
                </div>
                <div class="clear"></div>
            </div>
            <div class="section-content" v-else>
                <div class="content-preview" v-for="(image, index) in gallery" :key="index">
                  <img v-bind:src="image.galleryImage.src" :alt="image.translation.text_alternative" style="max-height: 238px" @click="galleryShowImage(index)" />
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { CodeLoader } from 'vue-content-loader';
    export default {
        name: "Gallery",
        components: {
            CodeLoader,
        },
        data() {
            return {
                pageName: null,
                mainCategoryName: null,
                pageLoaded: false,
                categoryId: null,
                categories: [
                    {
                        category: {
                            id: null,
                        },
                        translation: {
                            name: null,
                        },
                    }
                ],
                gallery: [
                    {
                        galleryImage: {
                            galleryImage: {
                                id: null,
                            },
                            translation: {
                                name: null,
                                text_alternative: null,
                            },
                        },
                    }
                ],
            }
        },
        created(){
            this.getGalleryCategoriesByLanguageId();
            if (this.$route.params.gallery_category_id !== undefined && this.$route.params.gallery_category_id > 0){
              console.log("gallery_category_id: "+this.$route.params.gallery_category_id);
              this.getGalleryCategoryByLanguageId(this.$route.params.gallery_category_id);
            } else {
              this.getGalleryCategoryByLanguageId(1);
            }
        },
        methods: {
            async getGalleryCategoriesByLanguageId() {
                var self = this;
                axios.get(this.$siteUrlApi+'/gallery-categories-'+this.$userLanguageId)
                    .then(function (response) {
                        self.categories = response.data.categories;
                    })
            },
            async getGalleryCategoryByLanguageId(categoryId) {
                var self = this;
                self.pageLoaded = false;
                axios.get(this.$siteUrlApi+'/gallery-image-category-'+categoryId+'-'+this.$userLanguageId)
                    .then(function (response) {
                        self.categoryId = categoryId;
                        self.pageName = response.data.category.translation.name;
                        self.gallery = response.data.gallery;
                        self.pageLoaded = true;
                    })
            },
            async getGalleryByLanguageId() {
                var self = this;
                axios.get(this.$siteUrlApi+'/gallery-image-main-'+this.$userLanguageId)
                    .then(function (response) {
                        self.categoryId = null;
                        self.pageName = response.data.pageName;
                        self.mainCategoryName = response.data.pageName;
                        self.gallery = response.data.gallery;
                        self.pageLoaded = true;
                    })
            },
            galleryShowImage(imageId) {
                this.$emit('gallery-show-image', imageId, this.gallery);
            },
        }
    }
</script>

<style scoped>

</style>