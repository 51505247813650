<template>
    <div id="section-contact-full" class="section">
        <div class="conteiner" v-show="pageLoaded === false">
            <code-loader primaryOpacity="0.15"></code-loader>
        </div>
        <div class="conteiner" v-show="pageLoaded === true">
            <div class="section-header-end">
                <div><h4>{{ pageTitle }}</h4></div>
            </div>
            <div class="section-content">
                <div class="content-contact" v-if="galleryLoaded">
                    <img style="width: auto" :style="{height: imageCalculateHeight}" :src="$siteUrlPanel+'/uploads/gallery_images/thumbnail/'+gallery[0].galleryImage.id+'.jpg'" @click="galleryShowImage(0)" />
                </div>
                <div class="content-contact" v-html="pageText" id="text" ref="textBox"></div>
                <div class="content-contact" style="width: 100%">
                    <map-google></map-google>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { CodeLoader } from 'vue-content-loader';
    import MapGoogle from '../components/MapGoogle'
    export default {
        name: "Contact",
        components: {
            CodeLoader,
            MapGoogle,
        },
        data() {
            return {
                gallery: [],
                galleryLoaded: false,
                pageLoaded: false,
                pageTitle: null,
                pageText: null,
                textBoxHeight: null,
            }
        },
        created(){
            this.getTextPage();
            this.getGalleryByLanguageId(this.$userLanguageId);
        },
        computed: {
            imageCalculateHeight() {
                var style = window.getComputedStyle(document.getElementById("text"), null);
                return style.getPropertyValue("height");
            }
        },
        methods: {
            getTextPage() {
                var self = this;
                axios.get(this.$siteUrlApi+'/text-page-type-2-'+this.$userLanguageId)
                    .then(function (response) {
                        self.pageTitle = response.data.textPage.translation.title;
                        self.pageText = response.data.textPage.translation.text;
                        self.pageLoaded = true;
                    })
            },
            async getGalleryByLanguageId(languageId) {
                var self = this;
                axios.get(this.$siteUrlApi+'/gallery-image-category-2-'+languageId)
                    .then(function (response) {
                        self.gallery = response.data.gallery;
                        self.galleryLoaded = true;
                    })
            },
            galleryShowImage(imageId) {
                this.$emit('gallery-show-image', imageId, this.gallery);
            },
        }
    }
</script>

<style scoped>

</style>