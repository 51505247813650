<template>
    <div class="content">
        <div class="section">
            <div class="conteiner" v-if="pageLoaded === false">
                <code-loader primaryOpacity="0.15"></code-loader>
            </div>
            <div class="conteiner" v-else>
                <div class="section-header-end">
                    <div><h4>{{ pageName }}</h4></div>
                </div>
                <div class="section-content">
                    <div class="content-site-row" v-for="offer in offerLists" :key="offer.offerList.offerList.id">
                        <div class="content-site">
                            <div class="content-site-gallery-big" v-if="offer.gallery !== undefined && offer.gallery.length > 0">
                                <img :src="$siteUrlPanel+'/uploads/gallery_images/height_max_500px/'+offer.gallery[0].galleryImage.id+'.jpg'" @click="$emit('gallery-show-image', 0, offer.gallery)" />
                            </div>
                            <div class="content-site-gallery">
                                <div class="content-site-gallery-row" v-if="offer.gallery !== undefined && offer.gallery.length > 1">
                                    <div class="content-site-gallery-min" v-for="(image, index) in offer.gallery" :key="image.galleryImage.id" @click="$emit('gallery-show-image', index, offer.gallery)">
                                        <div class="zoom"><span class="fas fa-search-plus"></span></div>
                                        <div>
                                            <img :src="$siteUrlPanel+'/uploads/gallery_images/thumbnail/'+image.galleryImage.id+'.jpg'" />
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                        <div class="content-site">
                            <h6>{{ offer.offerList.translation.name }}</h6>
                            <p v-html="offer.offerList.translation.text"></p>
                            <div class="content-site-price" v-if="offer.priceList.priceList !== null"><h6>Cena: {{ offer.priceList.priceList.price }} zł</h6></div>
                        </div>
                        <div class="clear"></div>
                        <hr>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { CodeLoader } from 'vue-content-loader';
    export default {
        name: "Services",
        components: { CodeLoader },
        data() {
            return {
                pageName: null,
                pageLoaded: false,

                galleries: [[{ id:null }]],

                offerLists: [{
                    offerList: {
                        offerList: {
                            id:null,
                            name:null,
                            text:null,
                        },
                        translation: {
                            name:null,
                            text:null,
                        },
                    },
                    priceList: {
                        priceList: {
                            id:null,
                            price:null,
                            duration:null,
                        },
                        translation: {
                            name:null,
                            text:null,
                        },
                    },
                    gallery: [{ id:null, }],
                }],
            }
        },
        created(){
            this.getOfferPage();
        },
        methods: {
            async getOfferPage() {
                var self = this;
                axios.get(this.$siteUrlApi+'/offer-list-'+this.$userLanguageId)
                    .then(function (response) {
                        self.offerLists = response.data.offerLists;
                        self.pageLoaded = true;
                    })
            },
        }
    }
</script>

<style scoped>

</style>