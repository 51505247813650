<template>
  <div class="bg"></div>
  <div class="gallery-slider" v-show="gallery.galleryIsOpen">
    <div class="gallery-slider-content">
      <div class="gallery-slider-img" :class="!gallery.galleryIsHorizontal ? 'vertical-img' : ''">
        <div class="gallery-slider-nav" :class="!gallery.galleryIsHorizontal ? 'vertical-img-nav' : ''">
          <div class="gallery-slider-nav-left" v-if="gallery.images.length > 1"><span class="fas fa-chevron-left" @click="galleryShowImagePrev"></span></div>
          <div class="gallery-slider-nav-right" v-if="gallery.images.length > 1"><span class="fas fa-chevron-right" @click="galleryShowImageNext"></span></div>
        </div>
        <div class="gallery-slider-img" :class="!gallery.galleryIsHorizontal ? 'vertical-img' : ''">
          <img :src="gallery.gallerySrc" :class="!gallery.galleryIsHorizontal ? 'vertical-img' : ''" />
        </div>
        <div class="gallery-slider-txt" v-html="gallery.galleryText"></div>
      </div>
    </div>
  </div>
  <header>
    <div class="conteiner">
      <router-link to="/"><div class="logo"><h1>Piekarnia Twoja</h1></div></router-link>
      <div class="menu" v-show="gallery.galleryIsOpen">
        <ul>
          <li><span class="fa fa-times" @click="galleryShowClose()"></span></li>
        </ul>
      </div>
      <div class="menu-mobile" v-show="gallery.galleryIsOpen">
        <ul>
          <li><span class="fa fa-times" @click="galleryShowClose()"></span></li>
        </ul>
      </div>
      <div class="menu" v-show="!gallery.galleryIsOpen">
        <ul>
          <li><router-link to="/">O nas</router-link></li>
          <li><router-link to="/services">Usługi</router-link></li>
          <li><router-link to="/gallery">Galeria</router-link></li>
          <li><router-link to="/contact">Kontakt</router-link></li>
        </ul>
      </div>
      <div class="menu-mobile" v-show="!gallery.galleryIsOpen">
        <ul>
          <li><span class="fas fa-bars" @click="mobileMenuOpenClose"></span></li>
        </ul>
      </div>
      <div class="menu-mobile-content" v-show="mobileMenuIsOpen">
        <div>
          <ul>
            <li><router-link @click="mobileMenuClose" to="/">Start</router-link></li>
            <li><router-link @click="mobileMenuClose" to="/services">Usługi</router-link></li>
            <li><router-link @click="mobileMenuClose" to="/gallery">Galeria</router-link></li>
            <li><router-link @click="mobileMenuClose" to="/contact">Kontakt</router-link></li>
          </ul>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </header>
  <div class="content">
    <router-view v-on:gallery-show-image="galleryShowImage" />
  </div>
  <footer>
    <div class="conteiner">
      <div class="menu">
        <ul>
          <li><router-link to="/">O nas</router-link></li>
          <li><router-link to="/services">Usługi</router-link></li>
          <li><router-link to="/gallery">Galeria</router-link></li>
          <li><router-link to="/contact">Kontakt</router-link></li>
        </ul>
      </div>
      <div class="reservation">
        <div><h3>Zadzwoń do nas <i class="fas fa-phone"></i></h3></div>
        <div><a href="tel:604-401-156"><h2 class="select">604 401 156</h2></a></div>
      </div>
      <div class="clear"></div>
    </div>
  </footer>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                gallery: {
                    galleryIsOpen: false,
                    galleryIsHorizontal: false,
                    galleryImageCurrentId: 0,
                    gallerySrc: '',
                    galleryText: '',
                    images: [{
                        galleryImage: {
                            id: null,
                            width: null,
                            height: null,
                        },
                        translation: {
                            name: null,
                        },
                    }],
                },
                mobileMenuIsOpen: false,
                panelUrl: null
            }
        },
        created(){
            this.panelUrl = this.$siteUrlPanel;
        },
        methods: {
            galleryShowImage(imageCurrentId, galleries){
                this.gallery.images = galleries;
                this.gallery.galleryImageCurrentId = imageCurrentId;
                this.galleryShowImageChange();
                this.gallery.galleryIsOpen = true;
            },
            galleryShowImageChange(){
                let image = this.gallery.images[this.gallery.galleryImageCurrentId];
                if (image.galleryImage.src !== undefined){
                  this.gallery.gallerySrc = image.galleryImage.src;
                } else {
                  this.gallery.gallerySrc = this.$siteUrlPanel+'/uploads/gallery_images/'+image.galleryImage.id+'.jpg';
                }
                if (image.galleryImage.width > image.galleryImage.height){
                    this.gallery.galleryIsHorizontal = true;
                }
                else {
                    this.gallery.galleryIsHorizontal = false;
                }
                this.gallery.galleryText = image.translation.name;
            },
            galleryShowImagePrev(){
                this.gallery.galleryImageCurrentId -= 1;
                if (this.gallery.galleryImageCurrentId < 0){
                    this.gallery.galleryImageCurrentId = this.gallery.images.length - 1;
                }
                this.galleryShowImageChange();
            },
            galleryShowImageNext(){
                this.gallery.galleryImageCurrentId += 1;
                if (this.gallery.galleryImageCurrentId >= this.gallery.images.length){
                    this.gallery.galleryImageCurrentId = 0;
                }
                this.galleryShowImageChange();
            },
            galleryShowClose(){
                this.gallery.galleryIsOpen = false;
            },
            mobileMenuOpenClose(){
                this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
            },
            mobileMenuClose(){
                this.mobileMenuIsOpen = false;
            },
        }
    }
</script>

<style>

</style>
