<template>
    <div class="content">
        <div class="section">
            <div class="conteiner" v-if="pageLoaded === false">
                <code-loader primaryOpacity="0.15"></code-loader>
            </div>
            <div class="conteiner" v-else>
                <div class="section-header-end">
                    <div><h4>{{ page_title }}</h4></div>
                </div>
                <div class="section-content">
                    <div class="content-site-row" v-html="page_text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "Rules",
    data() {
        return {
            pageLoaded: false,
            page_title: null,
            page_text: null,
        }
    },
    created(){
        this.getTextPage();
    },
    methods: {
        async getTextPage() {
            var self = this;
            axios.get(this.$siteUrlApi+'/text-page-type-3-'+this.$userLanguageId)
                .then(function (response) {
                    self.page_title = response.data.textPage.translation.title;
                    self.page_text = response.data.textPage.translation.text;
                    self.pageLoaded = true;
                })
        },
    }
}
</script>

<style scoped>

</style>