import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---

const app = createApp(App).use(router)
app.config.globalProperties.$siteUrlPanel = process.env.VUE_APP_URL_BACKEND;
app.config.globalProperties.$siteUrlApi = app.config.globalProperties.$siteUrlPanel+"/api";
app.config.globalProperties.$userLanguageId = 1;

import axios from 'axios'
import VueAxios from 'vue-axios'
app.use(VueAxios, axios)

import Vuex from 'vuex'
app.use(Vuex)

app.mount('#app')
