<template>
  <div id="section-about-as" class="section">
    <div class="conteiner" v-if="aboutAsTitle === null">
      <code-loader primaryOpacity="0.15"></code-loader>
    </div>
    <div class="conteiner" v-else>
      <div class="section-header-end">
        <div><h4>{{ aboutAsTitle }}</h4></div>
      </div>
      <div class="section-content" v-html="aboutAsText"></div>
    </div>
  </div>
  <div class="section">
    <div class="conteiner">
      <div class="section-content home-container-images">
        <router-link :to="{ name: 'Gallery', params: { gallery_category_id: 3 }}">
          <img src="/images/home/pl/gallery_3.jpg" class="home-image" />
        </router-link>
        <router-link :to="{ name: 'Gallery', params: { gallery_category_id: 6 }}">
          <img src="/images/home/pl/gallery_6.jpg" class="home-image" />
        </router-link>
        <router-link :to="{ name: 'Gallery', params: { gallery_category_id: 7 }}">
          <img src="/images/home/pl/gallery_7.jpg" class="home-image" />
        </router-link>
        <router-link :to="{ name: 'Gallery', params: { gallery_category_id: 4 }}">
          <img src="/images/home/pl/gallery_4.jpg" class="home-image" />
        </router-link>
        <router-link :to="{ name: 'Gallery', params: { gallery_category_id: 8 }}">
          <img src="/images/home/pl/gallery_8.jpg" class="home-image" />
        </router-link>
      </div>
    </div>
  </div>
  <div id="section-contact" class="section">
    <div class="conteiner" v-if="contactTitle === null">
      <code-loader primaryOpacity="0.15"></code-loader>
    </div>
    <div class="conteiner" v-else>
      <div class="section-header-end">
        <div><h4>{{ contactTitle }}</h4></div>
      </div>
      <div class="section-content">
        <div class="content-contact">
          <map-google></map-google>
        </div>
        <div class="content-contact" v-html="contactText"></div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { CodeLoader } from 'vue-content-loader';
import MapGoogle from '../components/MapGoogle'
export default {
    name: 'Home',
    components: {
        CodeLoader,
        MapGoogle,
    },
    data() {
        return {
            gallery: [],
            galleryLoaded: false,
            contactTitle: null,
            contactText: null,
            aboutAsTitle: null,
            aboutAsText: null
        }
    },
    created(){
        this.getAboutUsText();
        this.getContactText();
        this.getGalleryByLanguageId(this.$userLanguageId);
    },
    methods: {
        async getAboutUsText() {
            var self = this;
            axios.get(this.$siteUrlApi+'/text-page-type-1-'+this.$userLanguageId)
                .then(function (response) {
                    self.aboutAsTitle = response.data.textPage.translation.title;
                    self.aboutAsText = response.data.textPage.translation.text;
                })
        },
        async getContactText() {
            var self = this;
            axios.get(this.$siteUrlApi+'/text-page-type-2-'+this.$userLanguageId)
                .then(function (response) {
                    self.contactTitle = response.data.textPage.translation.title;
                    self.contactText = response.data.textPage.translation.text;
                })
        },
        async getGalleryByLanguageId(languageId) {
            var self = this;
            axios.get(this.$siteUrlApi+'/gallery-image-category-1-'+languageId)
                .then(function (response) {
                    self.gallery = response.data.gallery;
                    self.galleryLoaded = true;
                })
        },
        galleryShowImage(imageId) {
            this.$emit('gallery-show-image', imageId, this.gallery);
        },
    }
}
</script>

<style scoped>
.home-container-images{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 0.5rem;
  overflow: hidden;
}
.home-image{
  width: 100%;
}
</style>
